import { getLanguage, t, translate } from 'react-switch-lang';
import { Form } from 'react-final-form';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser as setSentryUser } from '@sentry/nextjs';
import ReCAPTCHA from 'react-google-recaptcha';

import Link, { ExternalLink } from '../Link';
import Button from '../Button';
import { Default, Password } from '../Field';
import { events, logAmpEvent, setAmpUser } from '../../utils/Amplitude';
import style from '../../styles/AuthForm.module.scss';
import GoogleRecaptchaDisclaimer from './GoogleRecaptchaDisclaimer';
import { validateEmail } from '../../utils/Validation';
import { getReCaptchaToken, invalidFormAlert } from '../../utils/Helpers';
import { setEmail } from '../../redux/actions/AuthActions';
import { setScreenReaderAlert } from '../../utils/Accessibility';
import { isProduction } from '../../utils/HostingEnv';
import { AUTH_API, AUTH_STEP } from '../../utils/Constants';
import { useRouter } from '../Router';
import { genCognitoErrHandler, useCognito } from '../../utils/Cognito';
import ErrorAlert from '../alerts/ErrorAlert';

function LoginForm({ setStep }) {
  const dispatch = useDispatch();
  const cognito = useCognito();
  const router = useRouter();
  const [errorMsg, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const reCaptchaRef = useRef(null);

  const onSubmit = (async (values) => {
    setLoading(true);
    const email = values.email.toLowerCase().trim();

    const reCaptchaToken = getReCaptchaToken(reCaptchaRef.current, () => setLoading(false));
    if (!reCaptchaToken) return;

    cognito
      .authenticate(email, values.password, {
        recaptchaToken: reCaptchaToken,
      })
      .then(() => {
        dispatch(setEmail(email));
        setScreenReaderAlert(t('ScreenReaderAlert_LoginSuccess'));
        // identify user for analytics
        setAmpUser(email);
        if (isProduction) setSentryUser({ email });
        logAmpEvent(events.API_SUCCESS, { Call: `Cognito: ${AUTH_API.LOGIN}` });
        router.pushStatic(`/${getLanguage()}/services`);
        setSuccessful(true);
      })
      .catch(
        genCognitoErrHandler(AUTH_API.LOGIN, setError, {
          UserNotConfirmedException: () => {
            dispatch(setEmail(values.email));
            setScreenReaderAlert(t('ScreenReaderAlert_ConfirmEmailSent'));
            setStep(AUTH_STEP.CONFIRM_EMAIL);
          },
        })
      )
      .finally(() => setLoading(false));
  });

  function recaptchaFailedCallback() {
    logAmpEvent(events.GOOGLE_RECAPTCHA_ERROR);
    setError(t('GoogleRecaptchaFailureMsg'));
    setLoading(false);
  }

  const validate = (values) => {
    const errors = {};
    errors.email = validateEmail(values.email);
    if (!values.password) {
      errors.password = t('Validation_Required');
    }
    return errors;
  };

  return (
    <>
      <div className={style.headingContainer}>
        <h1 className={style.headingTitle}>
          {t('LoginRegister_Title')}
        </h1>
        <ExternalLink
          href={t('Link_PaySimply_Partner_Portal_PaysimplyServices')}
          text={t('LoginRegister_SubTitleLink')}
          aria-label={t('LoginRegister_SubTitleLinkDescription')}
          ampEvent={events.USER_CLICKED_PAYSIMPLY_SERVICES_LINK}
        />
      </div>
      <Form
        onSubmit={onSubmit}
        validate={(values) => validate(values)}
        render={({ handleSubmit, form: { getFieldState, submit }, invalid, errors }) => (
          <div className={style.authForm}>
            <form
              onSubmit={(event) => {
                invalidFormAlert(invalid, errors);
                handleSubmit(event);
              }}
              className={style.formContainer}
            >
              <div className={style.fieldsContainer}>
                <ReCAPTCHA
                  style={{ display: 'none' }}
                  theme="dark"
                  ref={reCaptchaRef}
                  size="invisible"
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
                  onErrored={() => { recaptchaFailedCallback(); }}
                  onChange={submit}
                />
                <ErrorAlert msg={errorMsg} fullWidth forgotPassword>
                  <Link
                    className={style.forgotPasswordLink}
                    href="/forgot-password"
                    title={t('LinkDescription_ForgotPassword')}
                    ampEvent={events.USER_CLICKED_FORGOT_PASSWORD}
                    onClick={() => {
                      dispatch(setEmail(getFieldState('email').value));
                    }}
                  >
                    {t('Login_ForgotPassword_Link')}
                  </Link>
                </ErrorAlert>
                <h2 className={style.formTitle}>{t('Login_Title')}</h2>
                <Default
                  label={t('Auth_Field_Email_Lbl')}
                  id="EmailInput"
                  name="email"
                  autoComplete="email"
                  placeholder={t('Auth_Field_Email_Placeholder')}
                />

                <Password
                  label={t('Auth_Field_Password_Lbl')}
                  id="PasswordInput"
                  name="password"
                />
                <Button
                  text={t('Login_SignIn_Btn')}
                  type="submit"
                  className={style.fullButton}
                  ampEvent={events.USER_CLICKED_LOGIN_BUTTON}
                  loading={loading}
                  successful={successful}
                  onClick={() => {
                    setError('');
                  }}
                />
                <GoogleRecaptchaDisclaimer />
                <p className={style.altLink}>
                  {`${t('Login_NeedAccount_Lbl')} `}
                  <Link
                    href="/register"
                    ampEvent={events.USER_CLICKED_REGISTER_LINK_FROM_LOGIN}
                  >
                    {t('Login_CreateAccount_Link')}
                  </Link>
                </p>
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
}

export default translate(LoginForm);
